/* eslint-disable no-fallthrough */
import {
  DeviceControlResponseStatus,
  DeviceFeederBowlType,
  DeviceFeederTareType,
  DeviceStatusIDs,
  DeviceType,
  FeederFoodType,
  PetDoorLockingMode,
} from '@constants/Device';
import { TagProfile } from '@constants/Tag';
import { lens } from '@dhmk/zustand-lens';
import {
  DeviceCatFlapControlModel,
  DeviceCatFlapCurfew,
  DeviceCerberusControlModel,
  DeviceFeederControlModel,
  DeviceFeederTypeUpdated,
  DeviceFelaquaControlModel,
  DeviceHubControlModel,
  DeviceLoadingControl,
  DeviceModel,
  DevicePendingStatusModel,
  DevicePetDoorControlModel,
  DevicePetDoorCurfew,
  ServerControlResponseModel,
} from '@models/Device';
import { DeviceTagModel } from '@models/DeviceTag';
import { PetModel } from '@models/Pet';
import { de } from 'date-fns/locale';
import { cloneDeep } from 'lodash-es';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { StateCreator } from 'zustand';

import DeviceApi from '../../api/DeviceApi';
import { DdLogs } from '../../services/SPDataDogService';
import { MergedInterfaces } from '../models';
import { DeviceSliceModel } from '../models/DeviceSlice';
import { DateTime } from 'luxon';

type ZeroScalingErrorType = { [key: string]: any };

const createDeviceSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  DeviceSliceModel
> = (set, get) => {
  return {
    deviceStore: lens((subSet, subGet) => ({
      updateTagProfileLoading: false,
      updateCurfewLoading: false,
      updateCloseDeviceResult: 'none',
      changeFoodTypeResult: 'none',
      updatePortioningResult: 'none',
      updatingHubLedMode: false,
      updateTagProfileResult: 'none',
      deviceData: [],
      allDeviceData: [],
      loadingDevice: false,
      firstRequestDataFetched: true,
      error: null,
      success: null,
      productSetupSelection: null,
      loadingControl: {},
      assumedPendingTags: [],
      tagAssignmentStatus: [],
      asyncRequestState: {},
      devicesIdsRequestsTrack: {},
      lastZeroBowlTime: null,
      setLastZeroBowlTime: time => {
        subSet({
          lastZeroBowlTime: time,
        });
      },
      updateLockUnlockLoading: false,
      updateLockUnlockSuccess: false,
      updateLockUnlockError: null,
      setUpdateLockUnlockLoading: (status: boolean) => {
        subSet({ updateLockUnlockLoading: status });
      },
      setUpdateTagProfileLoading: (status: boolean) => {
        subSet({ updateTagProfileLoading: status });
      },
      setUpdateCurfewLoading: status => {
        subSet({
          updateCurfewLoading: status,
        });
      },
      resetLoadingControlJustSent: () => {
        const updatedLoadingControl: DeviceLoadingControl = {};
        const resetItem = <T extends { justSent?: boolean }>(item: T): T => {
          if (!item?.justSent) return item;
          return { ...item, justSent: false, request_id: null };
        };
        Object.entries(subGet().loadingControl).forEach(([key, value]) => {
          if (!value) return;
          switch (key) {
            case 'tag_profiles':
            case 'curfew':
              if (!Array.isArray(value) || !value.length) return;
              updatedLoadingControl[key] = value.map(resetItem);
              break;
            case 'bowls':
            case 'lid':
              if (!Object.keys(value).length) return;
              updatedLoadingControl[key] = resetItem(value);
              break;
            default:
              updatedLoadingControl[key] = { value: value.value };
          }
        });
        subSet({
          loadingControl: updatedLoadingControl,
        });
      },
      updateAssumedPendingTags: tagsArray => {
        subSet({
          assumedPendingTags: tagsArray,
        });
      },
      updateDevicesIdsRequestsTrack: (requests) => {
        // Access the current state of `devicesIdsRequestsTrack`
        const currentRequestsTrack = subGet().devicesIdsRequestsTrack;

        // Check if the new `requests` are different from the current state
        const newRequestsTrack = { ...(requests ?? {}) };
        const isDifferent = JSON.stringify(currentRequestsTrack) !== JSON.stringify(newRequestsTrack);

        // Only update if the requests have changed
        if (isDifferent) {
          subSet({
            devicesIdsRequestsTrack: newRequestsTrack,
          });
        }
      },
      refreshDeviceTags: async (deviceId: number) => {
        if (!deviceId) return;
        const tags = await DeviceApi.getDeviceTags(deviceId);
        subGet().updateDevice({ ...subGet().getDeviceById(deviceId), tags });
      },
      setProductSetupSelection: product => {
        subSet({ productSetupSelection: product });
      },
      updateProperty: data => subSet(data),
      getDeviceById: (id: number) => {
        AnalyticsService.logEvent('DeviceStore - getDeviceById');
        return subGet().deviceData.find(item => item.id === id) || null;
      },
      updateDevice: data => {
        console.log('updateDevice');
        try {
          AnalyticsService.logEvent('DeviceStore - updateDevice');
          const index = subGet().deviceData.findIndex(item => item.id === data.id);
          const { deviceData } = subGet();
          const newData = [...deviceData];

          console.log('INDEX: ', index);

          newData.splice(index, 1, { ...data });

          subSet({ deviceData: newData });
        } catch (e) {
          DdLogs.error('DeviceStore - updateDevice', 'updateDeviceError', JSON.stringify(e));
        }
      },
      deleteDevice: async id => {
        try {
          await AnalyticsService.logEvent('DeviceStore - deleteDevice');

          await DeviceApi.deleteDevice(id);
          subGet().loadDevice(true);
          return true;
        } catch ({ response }: any) {
          subSet({ error: response?.status || 500 });
          await DdLogs.error(
            'DeviceStore - deleteDevice',
            'deleteDeviceError',
            JSON.stringify(response),
          );
          return false;
        }
      },
      loadAllDeviceData: async () => {
        try {
          const allDeviceData = await DeviceApi.getDevices();
          subSet({ allDeviceData });
        } catch (error) {
          subSet({ allDeviceData: [] });
          console.error(error);
        }
      },
      fetchAndGetDeviceById: async (id: number) => {
        if (!get().householdStore.activeHousehold?.id) {
          await DdLogs.error('DeviceStore - fetchAndGetDeviceById', 'no active household');
          return;
        }
        subSet({ loadingDevice: true, error: null });
        try {
          const deviceDataResponse = await DeviceApi.getDevices(get().householdStore.activeHousehold?.id);
          const deviceData = Array.from(deviceDataResponse, (device) => ({...device, tags: device.tags || []}));
          subSet({
            deviceData,
          });
          return deviceData.find(item => item.id === id);
        } catch ({ response }) {
          // @ts-ignore
          subSet({ error: response?.status || 500 });
          await DdLogs.error('DeviceStore - fetchAndGetDeviceById', 'promise error', JSON.stringify(response));
        } finally {
          subSet({ loadingDevice: false, firstRequestDataFetched: false });
        }
      },
      loadForceDevice: async (): Promise<DeviceModel[]> => {
        if (!get().householdStore.activeHousehold?.id) {
          await DdLogs.error('DeviceStore - loadForceDevice', 'no active household');
          return;
        }
        subSet({ loadingDevice: true, error: null });
        try {
          const deviceDataResponse = await DeviceApi.getDevices(get().householdStore.activeHousehold?.id);
          const deviceData = Array.from(deviceDataResponse, (device) => ({...device, tags: device.tags || []}));
          subSet({
            deviceData,
          });
          return deviceData;
        } catch ({ response }) {
          // @ts-ignore
          subSet({ error: response?.status || 500 });
          await DdLogs.error('DeviceStore - loadForceDevice', 'promise error', JSON.stringify(response));
        } finally {
          subSet({ loadingDevice: false, firstRequestDataFetched: false });
        }
      },
      loadDevice: async (force = false) => {
        if (!get().householdStore.activeHousehold?.id) {
          DdLogs.error('DeviceStore - loadDevice', 'no active household');
          return;
        }
        await AnalyticsService.logEvent('DeviceStore - loadDevice', {
          skip: !force && subGet().deviceData.length,
        });
        if (!force && subGet().deviceData.length) {
          return;
        }

        subSet({ loadingDevice: true, error: null });

        try {
          const deviceData = await DeviceApi.getDevices(get().householdStore.activeHousehold?.id);
          console.log('loadDevice LOADED DEVICES');
          subSet({
            //
            deviceData: deviceData.map(item => {
              return {
                ...item,
                tags: item?.tags || [], // Map empty tags by default
              };
            }),
          });
        } catch ({ response }) {
          // @ts-ignore
          subSet({ error: response?.status || 500 });
          DdLogs.error('DeviceStore - loadDevice', 'promise error', JSON.stringify(response));
        } finally {
          subSet({ loadingDevice: false, firstRequestDataFetched: false });
        }
      },
      updateLockUnlockRequest: async (id, data) => {
        const loadingControlSetup = subGet().startLoaderControl({
          locking: { value: data.locking },
        });
        AnalyticsService.logEvent(`device_updateLockUnlockRequest`, {
          mode: data.locking,
        });
        await AnalyticsService.logEvent('DeviceStore - updateLockUnlockRequest');
        subSet({
          updateLockUnlockError: null,
          updateLockUnlockLoading: true,
        });
        try {
          const updatedData = await DeviceApi.lockUnlock(id, data);
          // @ts-expect-error: Types should be updated
          const requestId = updatedData?.results?.[0]?.request_id;
          // @ts-expect-error: Types should be updated
          const isPending = updatedData?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);

          const device = cloneDeep(subGet().getDeviceById(id));
          // @ts-expect-error: Types should be updated
          device.control.locking = data.locking;
          if (
            device.product_id === DeviceType.PetDoorConnect &&
            // @ts-expect-error: Types should be updated
            (device.control.curfew as DevicePetDoorCurfew).enabled
          ) {
            // @ts-expect-error: Types should be updated
            (device.control.curfew as DevicePetDoorCurfew).enabled = false;
          }

          subGet().updateDevice(device);
          subSet({
            updateLockUnlockError: null,
            updateLockUnlockSuccess: true,
          });
        } catch (error) {
          loadingControlSetup.clear();
          subSet({
            updateLockUnlockError: true,
            updateLockUnlockSuccess: null,
          });
        } finally {
          subSet({ updateLockUnlockLoading: false });
        }
      },
      updateCurfewSuccess: null,
      updateCurfewError: null,
      updateCurfewRequest: async (id, data) => {
        const loadingControlSetup = subGet().startLoaderControl({
          curfew: [{ device_id: id }],
        });
        await AnalyticsService.logEvent('DeviceStore - updateCurfewRequest');
        subSet({
          updateCurfewLoading: true,
        });

        try {
          const updatedData = await DeviceApi.updateCurfewAsync(id, data);
          const device = subGet().getDeviceById(id);

          let curfewEnabled = false;
          const requestId = updatedData?.results?.[0]?.request_id;
          const isPending = updatedData?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
          console.log('updateCurfewRequest.loadingControlSetup.updateRequestId', {
            requestId,
            isPending,
          });
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);

          if (isPending) {
            // @ts-expect-error: Types should be updated
            device.control.curfew = data.curfew;

            if (device.product_id === DeviceType.PetDoorConnect) {
              curfewEnabled = (data.curfew as DevicePetDoorCurfew).enabled;
            } else {
              curfewEnabled = (data.curfew as DeviceCatFlapCurfew[]).some(({ enabled }) => enabled);
            }
          }

          if (curfewEnabled && device.product_id === DeviceType.PetDoorConnect) {
            (device.control as DeviceCatFlapControlModel | DevicePetDoorControlModel).locking =
              PetDoorLockingMode.curfew;
          }

          subGet().updateDevice(device);
          subSet({ updateCurfewSuccess: device });
        } catch (e) {
          loadingControlSetup.clear();
          subSet({ updateCurfewError: e });
          await DdLogs.error(
            'DeviceStore - updateCurfewRequest',
            'updateCurfewError',
            JSON.stringify(e),
          );
        } finally {
          subSet({ updateCurfewLoading: false });
        }

        // subSet({ loadingDevice: false });
      },
      updateNameSuccess: null,
      updateNameError: null,
      updateNameRequest: async (id, data, isSetupFlow = true) => {
        subSet({ loadingDevice: true });

        try {
          await AnalyticsService.logEvent('DeviceStore - updateNameRequest');
          const updatedData = await DeviceApi.updateName(id, data);
          const device = subGet().getDeviceById(id);

          device.name = updatedData.name;

          subGet().updateDevice(device);
          if (!isSetupFlow) {
            subSet({ updateNameSuccess: device });
          }
        } catch (e) {
          subSet({ updateNameError: 'Something went wrong' });
          await DdLogs.error(
            'DeviceStore - updateNameRequest',
            'updateNameError',
            JSON.stringify(e),
          );
        }

        subSet({ loadingDevice: false });
      },
      assignUnassignPetError: null,
      unassignPetSuccess: null,
      unassignAssignMultiplePetsRequest: async (id, petsData) => {
        const loadingControlSetup = subGet().startLoaderControl({
          tag_profiles: petsData.map(pet => ({
            tag_id: pet.tagId,
            action: pet.assign ? 1 : 0,
          })),
        });
        await AnalyticsService.logEvent('DeviceStore - unassignAssignMultiplePetsRequest');
        // TODO: add types
        async function applyPetsAsync(): Promise<any> {
          const results = [];
          const finalRes = [];
          for (const petData of petsData) {
            subSet({
              devicesIdsRequestsTrack: {
                ...subGet().devicesIdsRequestsTrack,
                [id]: [...(subGet().devicesIdsRequestsTrack[id] ?? []), petData.tagId],
              },
            });

            if (petData.assign) {
              results.push({
                tag_id: petData.tagId,
                request_action: 1,
              });
              finalRes.push([true]);
            } else {
              results.push({
                tag_id: petData.tagId,
                request_action: 2,
              });
              finalRes.push([false]);
            }
          }
          console.log(
            'unassignAssignMultiplePetsRequest.applyPetsAsync.beforeRequest',
            subGet().loadingControl,
          );
          const res = await DeviceApi.assignPetAsync(id, results);
          if (res?.length) {
            //get ids of all pets that needs to be assigned or de-assigned;
            let assumedPendingPets = petsData.map(pet => pet.tagId);
            // parseResponse of returned pets and in result we have [[petTagId:number:undefined, request_id:string|undefined]]
            const returnedPets = res.map(item => [item?.data?.id, item?.results?.[0]?.request_id]);

            //for each Pet updating request_id and removing it's id from assumedPendingPets;
            returnedPets.forEach(returnedPet => {
              //check if petId Exists
              if (returnedPet[0]) {
                loadingControlSetup.unassignAssignMultiplePetsUpdateRequestID(
                  returnedPet[0],
                  returnedPet[1],
                );
                assumedPendingPets = assumedPendingPets.filter(id => id !== returnedPet[0]);
              }
            });
            //check if some of the pet doesn't receive response
            if (assumedPendingPets.length) {
              assumedPendingPets.forEach(petId => {
                // we are set up here null to request_id so in next DeviceStatus check tick this item will be removed
                loadingControlSetup.unassignAssignMultiplePetsUpdateRequestID(petId, null);
              });
            }
          } else {
            // just in case when result empty at all we're clearing all items setup from earlier
            loadingControlSetup.clear();
          }
          console.log(
            'unassignAssignMultiplePetsRequest.applyPetsAsync.afterUpdate',
            subGet().loadingControl,
          );
          //await 1 second
          await DeviceApi.getDeviceStatus(id);
          res.forEach((item, index) => {
            finalRes[index].push(item);
          });
          return finalRes;
        }

        try {
          const result = await applyPetsAsync();
          const device = subGet().getDeviceById(id);
          console.log('FQ4 updateDevice unassignAssignMultiplePetsRequest', device);
          subSet({
            assignPetSuccess: device,
          });
          return true;
        } catch (e) {
          console.log('[ERROR] [unassignAssignMultiplePetsRequest]', e);
          loadingControlSetup.clear();
          subSet({
            // @ts-expect-error: Types should be updated
            assignUnassignPetError: get(e, 'data.error.message[0]') || 'Sorry try again',
          });
          await DdLogs.error(
            'DeviceStore - unassignAssignMultiplePetsRequest',
            'assignUnassignPetError',
            JSON.stringify(e),
          );
          return false;
        }
      },
      startLoaderControl: (loadingControlParams: DeviceLoadingControl) => {
        const loadingControlKeys = Object.keys(loadingControlParams).filter(
          key => !!loadingControlParams[key],
        );
        const tempKey = `${loadingControlKeys.join('_')}_${Date.now()}`;
        const initItem = item => {
          return {
            ...item,
            justSent: true,
            request_id: tempKey,
          };
        };
        if (loadingControlKeys.length) {
          subSet({
            loadingControl: {
              ...subGet().loadingControl,
              ...Object.fromEntries(
                loadingControlKeys.map(key => [
                  key,
                  Array.isArray(loadingControlParams[key])
                    ? [
                        ...loadingControlParams[key].map(initItem),
                        ...(subGet().loadingControl?.[key] ?? []),
                      ]
                    : {
                        ...(subGet().loadingControl?.[key] ?? {}),
                        ...initItem(loadingControlParams[key]),
                      },
                ]),
              ),
            },
          });
        }
        return {
          updateRequestId: (requestId?: string) => {
            //in case if requestId there is undefined updateDeviceStatusHook stops to track items passed earlier on startLoaderControl call;
            const updateItem = item => {
              if (item?.request_id !== tempKey) return item;
              return {
                ...item,
                request_id: requestId,
                justSent: false,
              };
            };
            subSet({
              loadingControl: {
                ...subGet().loadingControl,
                ...Object.fromEntries(
                  loadingControlKeys
                    .filter(
                      key =>
                        !!(
                          subGet().loadingControl?.[key] &&
                          (subGet().loadingControl[key]?.length ||
                            Object.keys(subGet().loadingControl[key]).length)
                        ),
                    )
                    .map(key => [
                      key,
                      Array.isArray(subGet().loadingControl[key])
                        ? subGet().loadingControl[key].map(updateItem)
                        : updateItem(subGet().loadingControl[key]),
                    ]),
                ),
              },
            });
          },
          clear: () => {
            const clearItem = item => {
              if (item.request_id !== tempKey) return item;
              return {
                ...item,
                justSent: false,
                request_id: null,
              };
            };
            subSet({
              loadingControl: {
                ...subGet().loadingControl,
                ...Object.fromEntries(
                  loadingControlKeys
                    .filter(
                      key =>
                        !!(
                          subGet().loadingControl?.[key] &&
                          (subGet().loadingControl[key]?.length ||
                            Object.keys(subGet().loadingControl[key]).length)
                        ),
                    )
                    .map(key => [
                      key,
                      Array.isArray(subGet().loadingControl[key])
                        ? subGet().loadingControl[key].map(clearItem)
                        : clearItem(subGet().loadingControl[key]),
                    ]),
                ),
              },
            });
          },
          unassignAssignMultiplePetsUpdateRequestID: (tag_id: number, request_id: string) => {
            if (!subGet().loadingControl?.tag_profiles?.length) return;
            const updatedTagProfiles = subGet().loadingControl.tag_profiles.map(tag_profile => {
              if (
                tag_profile.justSent &&
                tag_profile.tag_id === tag_id &&
                tag_profile.request_id === tempKey
              ) {
                return { ...tag_profile, justSent: false, request_id };
              }
              return tag_profile;
            });
            subSet({
              loadingControl: {
                ...subGet().loadingControl,
                tag_profiles: updatedTagProfiles,
              },
            });
          },
        };
      },
      davidsUpdateTagProfile: async (deviceId, pet, profile: TagProfile) => {
        const loadingControlSetup = subGet().startLoaderControl({
          tag_profiles: [{ tag_id: pet.tag_id }],
        });
        await AnalyticsService.logEvent('DeviceStore - updateTagProfile');
        try {
          console.log(
            'davidsUpdateTagProfile.BeforeRequest.loaderControl',
            subGet().loadingControl,
          );
          const updateTagRes = await DeviceApi.putDeviceTagAsync(deviceId, [
            { tag_id: pet.tag_id, request_action: 0, profile },
          ]);
          if (updateTagRes) {
            const requestId = updateTagRes?.data?.[0]?.results?.[0]?.request_id;
            const isPending =
              updateTagRes?.data?.[0]?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
            console.log('davidsUpdateTagProfile.loadingControlSetup.updateRequestId', {
              requestId,
              isPending,
            });
            loadingControlSetup.updateRequestId(isPending ? requestId : undefined);
            subSet({ updateTagProfileResult: 'success' });
            console.log(
              'davidsUpdateTagProfile.AfterRequest.loaderControl',
              subGet().loadingControl,
            );
          }
          return profile;
        } catch (e) {
          loadingControlSetup.clear();
          subSet({ updateTagProfileResult: 'error' });
          await DdLogs.error(
            'DeviceStore - updateTagProfile',
            'updateTagProfileError',
            JSON.stringify(e),
          );
          return TagProfile.None;
        } finally {
          subSet({ updateTagProfileLoading: false });
        }
      },
      assignPetSuccess: null,
      assignPetError: null,
      updateZeroScalesSuccess: null,
      updateZeroScalesError: null,
      updateZeroScalesLoading: false,

      updateZeroScalesAsync: async (id, type) => {
        const loadingControlSetup = subGet().startLoaderControl({
          tare: { value: type },
        });
        AnalyticsService.logEvent(`device_updateZeroScales`);
        subSet({
          updateZeroScalesError: null,
          updateZeroScalesSuccess: null,
          updateZeroScalesLoading: true,
        });

        try {
          const res = await DeviceApi.updateZeroScalesAsync(id, type);
          if (res) {
            const requestId = res?.results?.[0]?.request_id;
            const isPending = res?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
            console.log('updateZeroScalesAsync.loadingControlSetup.updateRequestId', {
              requestId,
              isPending,
            });
            loadingControlSetup.updateRequestId(isPending ? requestId : undefined);

            const noChange = (res.results || []).some(
              item => item.status === DeviceControlResponseStatus.Same,
            );
            if (noChange) {
              console.log('no change');
              subSet({ updateZeroScalesLoading: false });
            }
          }
        } catch (e) {
          loadingControlSetup.clear();
        }
      },
      setUpdateZeroScalesLoading: (loading: boolean) => {
        subSet({ updateZeroScalesLoading: loading });
      },
      setUpdateZeroScalesSuccess: (success: boolean) => {
        subSet({ updateZeroScalesSuccess: success });
      },
      setUpdateZeroScalesError: (error: DeviceStatusIDs) => {
        subSet({ updateZeroScalesError: { error } });
      },
      refreshUpdateZeroScalesStatus: (deviceId: number) => {
        const deviceAsyncRequestState: DevicePendingStatusModel[] | undefined =
          subGet().asyncRequestState[deviceId];
        const setUpdateZeroScalesLoading = subGet().setUpdateZeroScalesLoading;
        const setUpdateZeroScalesSuccess = subGet().setUpdateZeroScalesSuccess;
        const setUpdateZeroScalesError = subGet().setUpdateZeroScalesError;

        if (
          !deviceAsyncRequestState ||
          !Array.isArray(deviceAsyncRequestState) ||
          !deviceAsyncRequestState.length
        ) {
          console.log('No asyncRequestState for device', deviceId);
          return;
        }
        const lastStatus: DevicePendingStatusModel | undefined = deviceAsyncRequestState.reduce(
          (status, item) => {
            if (item?.state && Object.keys(item?.state).includes('tare')) {
              return item;
            }
            return status;
          },
          undefined,
        );

        if (!lastStatus) {
          // console.log(`No last status was found for device ${deviceId}`);
          return;
        }
        setUpdateZeroScalesLoading(lastStatus.status === DeviceStatusIDs.Pending);
        setUpdateZeroScalesSuccess(lastStatus.status === DeviceStatusIDs.Success);
        // if status is an error and requested_at is within the last hour
        const requestedAtDateTime: DateTime = DateTime.fromISO(lastStatus.requested_at);
        const fiveMinsAgo: DateTime = DateTime.now().minus({ minute: 5 });
        if (
          [
            DeviceStatusIDs.DeviceError,
            DeviceStatusIDs.ServerError,
            DeviceStatusIDs.NoChange,
          ].includes(lastStatus.status) &&
          requestedAtDateTime > fiveMinsAgo
        ) {
          console.log('last request was an error', lastStatus.requested_at, lastStatus.status);
          setUpdateZeroScalesError(lastStatus.status);
        } else {
          setUpdateZeroScalesError(null);
        }
      },
      updateFoodTypeAsync: async (id, data) => {
        AnalyticsService.logEvent(`device_updateFoodType`);
        const loadingControlSetup = subGet().startLoaderControl({
          bowls: {
            ...data,
          },
        });
        try {
          const updatedData = await DeviceApi.updateFoodTypeAsync(id, {
            settings: data.settings,
            type: data.type || null,
          });
          const requestId = updatedData?.results?.[0]?.request_id;
          const isPending = updatedData?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
          console.log('updateFoodTypeAsync.loadingControlSetup.updateRequestId', {
            requestId,
            isPending,
          });
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);
          const device = subGet().getDeviceById(id) as DeviceModel;
          const deviceControl = device.control as DeviceFeederControlModel;
          deviceControl.bowls = deviceControl.bowls || {};

          if (updatedData?.pending.length) {
            deviceControl.bowls.settings = data.settings;
            (deviceControl.bowls.type as DeviceFeederBowlType) = data.type;
          }
          subGet().updateDevice({ ...device, control: deviceControl });
          subSet({ changeFoodTypeResult: 'success', loadingDevice: false });
          return true;
        } catch (e) {
          loadingControlSetup.clear();
          subSet({
            error: e as ServerControlResponseModel,
            changeFoodTypeResult: 'error',
            loadingDevice: false,
          });
          return false;
        }
      },
      updateCerberusControl: async (id, data) => {
        const loadingControlSetup = subGet().startLoaderControl({
          bowls_control: data,
        });
        subSet({ loadingDevice: true });
        try {
          const newCerberusControl = {
            substance_type: data.substance_type,
            food_type: data.food_type,
          };
          const { data: updatedData, results } = (await DeviceApi.updateCerberusControlAsync(
            id,
            newCerberusControl,
          )) as { data: DeviceCerberusControlModel; results: DevicePendingStatusModel[] };

          loadingControlSetup.updateRequestId(
            results[0]?.status_id === DeviceStatusIDs.Pending ? results[0]?.request_id : undefined,
          );
          const device = subGet().getDeviceById(id);

          (device.control as DeviceCerberusControlModel) = {
            ...device.control,
            ...updatedData,
            ...data,
          };
          subGet().updateDevice(device);
          subSet({ success: true, loadingDevice: false });
          return true;
        } catch (e) {
          loadingControlSetup.clear();
          subSet({
            error: e as ServerControlResponseModel,
            loadingDevice: false,
          });
          return false;
        }
      },
      updateCloseDelaySuccess: null,
      updateCloseDelayError: null,
      updateCloseDelayAsync: async (id, data) => {
        const loadingControlSetup = subGet().startLoaderControl({ lid: { close_delay: data } });
        AnalyticsService.logEvent(`device_updateCloseDelay`);

        const device = subGet().getDeviceById(id);
        const { lid } = device.control as DeviceFeederControlModel;
        try {
          const response = await DeviceApi.updateCloseDelayAsync(id, {
            ...lid,
            close_delay: data,
          });
          const requestId = response?.results?.[0]?.request_id;
          const isPending = response?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
          console.log('updateCloseDelayAsync.loadingControlSetup.updateRequestId', {
            requestId,
            isPending,
          });
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);
          if (response.results[0].status_id === DeviceStatusIDs.Pending) {
            // @ts-expect-error: Types should be updated
            device.control.lid = { ...(device.control.lid ?? {}), close_delay: data };
          }
          subGet().updateDevice(device);
          subSet({ updateCloseDeviceResult: 'success' });
        } catch (e) {
          loadingControlSetup.clear();
          subSet({
            error: e as ServerControlResponseModel,
            updateCloseDeviceResult: 'error',
          });
        }
      },
      updateTargetAsync: async (id, data) => {
        subSet({ loadingDevice: true });

        //TODO check if type of type and data passed to it is correct
        const loadingControlSetup = subGet().startLoaderControl({
          bowls: {
            // @ts-expect-error: Types should be updated
            settings: [{ target: data }],
          },
        });
        AnalyticsService.logEvent(`device_updateTargetWeight`);

        try {
          const updatedData = await DeviceApi.updateTargetAsync(id, data);
          const requestId = updatedData.results[0]?.request_id;
          const isPending =
            updatedData.results[0]?.status_id === DeviceStatusIDs.Pending;
          console.log('updateTargetAsync.loadingControlSetup.updateRequestId',
            updatedData.results
          );
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);
          const device = subGet().getDeviceById(id);
          if (updatedData.results[0].status_id === DeviceStatusIDs.Pending) {
            // @ts-expect-error: Types should be updated
            device.control.bowls.settings = data.settings;
          }
          subGet().updateDevice(device);
          subSet({ loadingDevice: false });
          return true;
        } catch (e) {
          loadingControlSetup.clear();
          subSet({ loadingDevice: false });

          return false;
        }
      },
      updateBowlTypeAsync: async (id, type) => {
        const loadingControlSetup = subGet().startLoaderControl({
          bowls: {
            type,
          },
        });
        AnalyticsService.logEvent(`device_updateBowlType`);
        try {
          const updatedData = await DeviceApi.updateBowlTypeAsync(id, {
            type,
            settings: [
              {
                food_type: FeederFoodType.wet,
                target: 0,
              },
              {
                food_type: FeederFoodType.dry,
                target: 0,
              },
            ],
          });
          const requestId = updatedData?.results?.[0]?.request_id;
          const isPending = updatedData?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
          console.log('updateBowlTypeAsync.loadingControlSetup.updateRequestId', {
            requestId,
            isPending,
          });
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);
          const device = subGet().getDeviceById(id);

          if (updatedData.results[0].status_id === DeviceStatusIDs.Pending) {
            // @ts-expect-error: Types should be updated
            device.control.bowls.type = type;
          }

          subGet().updateDevice(device);

          subSet({ loadingDevice: false });
          return true;
        } catch (e) {
          loadingControlSetup.clear();
          subSet({
            error: e as ServerControlResponseModel,
            loadingDevice: false,
          });
          return false;
        }
      },
      updateDrinkingTare: async id => {
        const loadingControlSetup = subGet().startLoaderControl({
          tare: {
            value: 1,
          },
        });

        try {
          const updatedData = await DeviceApi.updateTareAsync(id, true);
          const requestId = updatedData?.results?.[0]?.request_id;
          const isPending = updatedData?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
          console.log('updateDrinkingTare.loadingControlSetup.updateRequestId', {
            requestId,
            isPending,
          });
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);
          const device = subGet().getDeviceById(id);

          (device.control as DeviceFelaquaControlModel) = updatedData;
          subGet().updateDevice(device);
        } catch (e) {
          loadingControlSetup.clear();
          subSet({ error: e as ServerControlResponseModel });
        }

        // subSet({ loadingDevice: false });
      },
      updateLedMode: async (id, data) => {
        const loadingControlSetup = subGet().startLoaderControl({
          led_mode: { value: data },
        });
        await AnalyticsService.logEvent(`device_updateLedMode`);
        subSet({ updatingHubLedMode: true });
        try {
          const updatedData = await DeviceApi.updateLedModeAsync(id, data);
          const device = subGet().getDeviceById(id);

          const requestId = updatedData?.results?.[0]?.request_id;
          const isPending = updatedData?.results?.[0]?.status_id === DeviceStatusIDs.Pending;
          console.log('updateLedMode.loadingControlSetup.updateRequestId', {
            requestId,
            isPending,
          });
          loadingControlSetup.updateRequestId(isPending ? requestId : undefined);

          if (updatedData.results[0].status_id === DeviceStatusIDs.Pending) {
            // @ts-expect-error: Types should be updated
            device.control.led_mode = data;
          }
          subGet().updateDevice(device);
          // subSet({ success: true });
        } catch (e) {
          loadingControlSetup.clear();
          subSet({ error: e as ServerControlResponseModel });
        } finally {
          subSet({ updatingHubLedMode: false });
        }
      },
      getDevicesCollection: () => {
        return subGet().deviceData.reduce<Record<number, DeviceModel>>((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});
      },
      refreshPetsDevices: async (idsArray, householdId) => {
        const newIdsArray = [];
        try {
          const responses = await DeviceApi.getAllDevicesStatus(householdId);
          const combinedData = responses
            .flat()
            .filter(item => item.status === DeviceStatusIDs.Pending);
          const result = combinedData.reduce((acc, item) => {
            if (item?.state?.tag_profiles?.[0]?.tag_id) {
              acc[item.device_id]
                ? acc[item.device_id].push(item.state.tag_profiles[0].tag_id)
                : (acc[item.device_id] = [item.state.tag_profiles[0].tag_id]);
            }
            newIdsArray.includes(`${item.device_id}`)
              ? null
              : newIdsArray.push(`${item.device_id}`);
            return acc;
          }, {});
          const resultKeys = Object.keys(result);
          for (const id of idsArray) {
            if (!resultKeys.includes(id + '')) {
              result[id] = [];
            }
          }

          let needRefresh = false;
          if (
            Object?.values(subGet().devicesIdsRequestsTrack)?.flat()?.length !==
            Object?.values(result)?.flat()?.length
          ) {
            needRefresh = true;
          }
          return { result, newIdsArray, needRefresh };
        } catch (e) {
          console.log('Device status ERROR =>', e);
        }
      },
      resetStatusFields: () => {
        subSet({
          error: null,
          success: null,
          loadingDevice: false,
        });
      },
      setLoadingControl: (value: DeviceLoadingControl) => {
        subSet({
          loadingControl: value,
        });
      },
      setAsyncRequestState: (deviceID: number, value: any[]) => {
        const unfilteredPart = [];
        const currDeviceRequests = subGet().assumedPendingTags.filter(item => {
          if (item?.deviceId === deviceID) {
            return true;
          } else {
            unfilteredPart.push(item);
            return false;
          }
        });

        const valueIds = new Set(value.map(item => item.request_id));
        const filteredAssumedPending = currDeviceRequests.filter(
          item => !valueIds.has(item.requestId),
        );
        subGet().updateAssumedPendingTags([...unfilteredPart, ...filteredAssumedPending]);

        if (value.length) {
          subSet({
            asyncRequestState: { ...subGet().asyncRequestState, [deviceID]: value },
          });
        } else {
          const toUpdate = { ...subGet().asyncRequestState };
          // remove any keys with empty arrays
          delete toUpdate[deviceID];

          subSet({
            asyncRequestState: toUpdate,
          });
        }
      },
    })),
  };
};
export default createDeviceSlice;
